
.icon-loading
  @apply mr-16
  &::before
    animation: spin 1.5s linear infinite

@keyframes spin
  0%
    transform: rotate(0deg)
  50%
    transform: rotate(180deg)
  100%
    transform: rotate(360deg)
